
export default {
    methods: {
        gettime(date) {
            var diff = this.$moment.duration(this.$moment().diff(this.$moment(date)));
            if (diff._data.days == 0) {
                if (diff._data.hours == 0) {
                    if (diff._data.minutes == 0) {
                        return this.$t("offer.seconds", { time: diff._data.seconds });
                    } else {
                        return this.$t("offer.minutes", { time: diff._data.minutes });
                    }
                } else {
                    return this.$t("offer.hours", { time: diff._data.hours });
                }
            } else {
                return this.$t("offer.days", { time: diff._data.days });
            }
        },
    },
};
