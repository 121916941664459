<template>
  <v-container class="ma-0 pa-0">
    <v-card
      color="white"
      class="rounded-lg mb-3"
      :to="{
        name: 'pharmacy-detail',
        params: {
          offer_id: offer.id,
        },
      }"
      v-for="(offer, index) in offers"
      :key="index"
    >
      <v-row class="pa-0 ma-0">
        <v-col cols="8" class="ma-0 pa-0 pl-4 mt-2"
          ><v-card-title
            class="ml-1 ma-0 pa-0 primary--text text-body-1 font-weight-medium"
            >{{ offer.position.name }}</v-card-title
          ></v-col
        >
        <v-col cols="4" class="ma-0 pa-0 mt-1 text-right"
          ><v-btn icon class="justify-end">
            <v-chip small class="mr-2" color="rgba(0, 0, 0, 0.12)"
              >{{ offer.expert_review ? "Magister" : "Alquimia" }}
            </v-chip>
          </v-btn></v-col
        >
      </v-row>
      <v-row class="secondary--text ma-0 pa-0">
        <v-col cols="6" class="mt-1 text-caption pa-0 text-left pl-4"
          ><v-icon size="17" color="secondary" class="mx-1"
            >mdi-clock-time-five-outline</v-icon
          >{{ gettime(offer.created) }}</v-col
        >
        <v-col cols="6" class="mt-1 text-caption text-left pa-0">
          <v-icon size="17" color="secondary" class="mr-1"
            >mdi-map-marker</v-icon
          >{{ offer | formatAddress }}</v-col
        >
      </v-row>
      <v-divider class="mx-8 mt-3"></v-divider>
      <v-card-actions>
        <v-icon color="secondary" class="mr-1 ml-2"
          >mdi-account-multiple
        </v-icon>
        <!-- <v-badge
          :content="message"
          :value="message"
          bordered
          bottom
          color="red"
          dot
          offset-x="10"
          offset-y="-2"
        >
        </v-badge> -->
        <span class="text-caption secondary--text ml-1 pb-1 pl-1">{{
          $t("offer.candidates", { number: offer.candidates_count })
        }}</span>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import Mixins from "@/mixins/index.js";
import { mapGetters } from "vuex";
export default {
  mixins: [Mixins],
  data() {
    return {
      message: " ",
    };
  },
  computed: {
    ...mapGetters({ offers: "offer/offers" }),
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
