<template>
  <v-container v-if="mounted">
    <layout :back="true" />
    <video-player
      v-if="offer.video"
      @closeVideoPlayer="toggleVideoPlayer"
      :showVideoPlayer.sync="videoPlayer"
      :src="offer.video"
    />
    <v-row justify-sm="center">
      <v-col cols="12" md="5" sm="8">
        <v-list-item class="primary--text">
          <v-list-item-content>
            <v-list-item-title class="primary--text"
              >{{ pharmacy.commercial_name }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-body-2 font-weight-light secondary--text"
            >
              {{ pharmacy.phone_number }}
            </v-list-item-subtitle>
            <v-row class="text-body-2 font-weight-light secondary--text pa-0">
              <v-col cols="10"> {{ "Madrid, España" }}.</v-col>
              <v-col cols="1" v-if="offer.video">
                <div class="mt-n3">
                  <v-btn
                    class="pa-0 mb-1"
                    color="primary"
                    @click="toggleVideoPlayer"
                  >
                    <v-icon> mdi-play-circle </v-icon>
                  </v-btn>
                </div></v-col
              >
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-2">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("tabs.contracted_offers") + ":"
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" justify-sm="center">
      <v-col cols="12" sm="8" md="5">
        <pharmacy-offers />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Layout component
import PharmacyOffers from "@/components/offers/PharmacyCards.vue";
import layout from "@/components/layouts/Profile.vue";
import VideoPlayer from "@/components/modals/VideoPlayer.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    layout,
    VideoPlayer,
    PharmacyOffers
  },
  data() {
    return {
      items: [
        this.$i18n.tc("offer.title", 1),
        this.$i18n.tc("candidate.title", 2, { number: "jo" })
      ],
      colors: ["grey", "#f39f18", "success", "error"],
      tab: "",
      videoPlayer: false,
      value: null,
      mounted: false,
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    };
  },
  async created() {
    this.enableLoading();
    await this.getPharmacyById(this.$route.params.pharmacy_id);
    await this.getExpertPharmacyOffers({ id: this.$route.params.pharmacy_id });
    this.disableLoading();
    this.mounted = true;
  },
  computed: {
    ...mapGetters({ offer: "offer/offer" }),
    ...mapGetters({ candidates: "match/candidates" }),
    ...mapGetters({ pharmacy: "pharmacy/pharmacy" })
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ getPharmacyById: "pharmacy/getPharmacyById" }),
    ...mapActions({ getExpertPharmacyOffers: "offer/getExpertPharmacyOffers" }),
    toggleVideoPlayer() {
      this.videoPlayer = !this.videoPlayer;
    }
  }
};
</script>
<style scoped>
.v-slider-group {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.v-divider {
  border-color: #718f94 !important;
}
.theme--light.v-tabs-items {
  background-color: transparent;
}
.detailText {
  font-size: 13px;
  margin-bottom: 3px;
  opacity: 0.6;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}
</style>
